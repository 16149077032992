import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  TextField,
  Button,
  IconButton,
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const BlueprintSpecSheet = () => {
  const [wallLevels, setWallLevels] = useState([{ level: 1 }]);
  const [generalLevels, setGeneralLevels] = useState([{ level: 1 }]);
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [quoteOrder, setQuoteOrder] = useState("");
  const [taxExempt, setTaxExempt] = useState("");

  // Modal handlers
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle address input
  const handleAddressChange = (e) => setAddress(e.target.value);

  // Google Maps URL generation based on address
  const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
    address
  )}&output=embed`;

  const addGeneralLevel = () => {
    setGeneralLevels([...generalLevels, { level: generalLevels.length + 1 }]);
  };
  const deleteGeneralLevel = (index) => {
    setGeneralLevels(generalLevels.filter((_, i) => i !== index));
  };

  const addWallLevel = () => {
    setWallLevels([...wallLevels, { level: wallLevels.length + 1 }]);
  };
  const deleteWallLevel = (index) => {
    setWallLevels(wallLevels.filter((_, i) => i !== index));
  };

  return (
    <div className="p-10">
      {/* Blueprint Spec Sheet Title */}
      <div className="text-center mb-8">
        <h1 className="text-2xl font-bold">Blueprint Spec Sheet</h1>
      </div>

      {/* Contractor and Project Info */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <TextField label="Contractor Name" fullWidth />
        <TextField label="Resident Name" fullWidth />
        <TextField label="Account Number" fullWidth />
        <div className="relative">
          <TextField
            label="Address"
            fullWidth
            value={address}
            onChange={handleAddressChange}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleOpen}>
                  <LocationOnIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <TextField label="Building Type" fullWidth />
        <TextField label="Bid Type" fullWidth />
        <TextField label="Other Bidders" fullWidth />
        <FormControl fullWidth>
          <InputLabel>State</InputLabel>
          <Select value={state} onChange={(e) => setState(e.target.value)}>
            <MenuItem value={"IN"}>Indiana</MenuItem>
            <MenuItem value={"MI"}>Michigan</MenuItem>
            <MenuItem value={"OH"}>Ohio</MenuItem>
            {/* Add more states or options here */}
          </Select>
        </FormControl>{" "}
        <FormControl fullWidth>
          <InputLabel>Quote / Order</InputLabel>
          <Select
            value={quoteOrder}
            onChange={(e) => setQuoteOrder(e.target.value)}
          >
            <MenuItem value={"Quote"}>Quote</MenuItem>
            <MenuItem value={"Order"}>Order</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Required Date"
          fullWidth
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth>
          <InputLabel>Tax Exempt?</InputLabel>
          <Select
            value={taxExempt}
            onChange={(e) => setTaxExempt(e.target.value)}
          >
            <MenuItem value={"Yes"}>Yes</MenuItem>
            <MenuItem value={"No"}>No</MenuItem>
          </Select>
        </FormControl>
      </div>

      {/* Notes */}
      <div className="mb-6">
        <TextField label="Notes" fullWidth multiline rows={4} />
      </div>

      {/* Products Need Quotes */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold">
          Following Products Need Quotes
        </h2>
        <div className="grid grid-cols-4 gap-4 mb-8">
          <Button startIcon={<AddIcon />} variant="outlined">
            Roofs
          </Button>
          <Button startIcon={<AddIcon />} variant="outlined">
            I-Joists
          </Button>
          <Button startIcon={<AddIcon />} variant="outlined">
            Floors
          </Button>
          <Button startIcon={<AddIcon />} variant="outlined">
            Wall Panels
          </Button>
        </div>
      </div>

      {/* General Project Information */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold">General Project Information</h2>

        {generalLevels.map((lvl, index) => (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-4 items-center"
          >
            {/* Display the level number */}

            <p className="justify-center items-center flex">
              {index === generalLevels.length - 1 &&
                generalLevels.length > 1 && (
                  <IconButton
                    color="primary"
                    onClick={() => deleteGeneralLevel(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              LEVEL {lvl.level}
            </p>

            {/* Floor Material */}
            <TextField label="Floor Material" fullWidth />

            {/* Member Depth Criteria */}
            <TextField label="Member Depth Criteria" fullWidth />

            {/* Max O.C. Spacing */}
            <TextField label="Max O.C. Spacing" fullWidth />

            {/* Deflection Criteria */}
            <TextField label="Deflection Criteria" fullWidth />
          </div>
        ))}

        {/* Add Level Button */}
        <div className="mt-4">
          <IconButton color="primary" onClick={addGeneralLevel}>
            <AddIcon /> Add Level
          </IconButton>
        </div>

        {/* Roof and Wall Information */}
        <div className="grid grid-cols-2 gap-4 mb-8">
          <TextField label="Roof Material" fullWidth />
          <TextField label="Wall Sheathing" fullWidth />
        </div>
      </div>

      {/* Roof and Wall Information */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold">
          Addition Roof Truss Information
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
          <TextField label="Loading" fullWidth />
          <TextField label="Overhang" fullWidth />
          <TextField label="Heel Height" fullWidth />
          <TextField label="Roof Pitch" fullWidth />
          <TextField label="Attic Storage" fullWidth />
          <TextField label="Heated Building" fullWidth />
          <TextField label="Truss Tie Downs" fullWidth />
          <TextField label="Truss Siding Clips" fullWidth />
          <TextField label="Girder Legs" fullWidth />
          <TextField label="Ceiling Conditions" fullWidth />
          <TextField label="Ceiling Pitch" fullWidth />
          <TextField label="Corner Sets" fullWidth />
          <TextField label="Piggy Back Type" fullWidth />
          <TextField label="Valley Sets" fullWidth />
          <TextField label="Drop Gables" fullWidth />
          <TextField label="Dormers" fullWidth />
        </div>
      </div>

      {/* Wall Information */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold">
          General Project Wall Information
        </h2>

        {wallLevels.map((lvl, index) => (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-4"
          >
            {/* Display the level number */}
            <p className="justify-center items-center flex">
              {index === wallLevels.length - 1 && wallLevels.length > 1 && (
                <IconButton
                  color="primary"
                  onClick={() => deleteWallLevel(index)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              LEVEL {lvl.level}
            </p>

            {/* Wall Height */}
            <TextField label="Wall Heights" fullWidth />

            {/* Stud Spacing */}
            <TextField label="Stud Spacing" fullWidth />

            {/* Sheathing */}
            <TextField label="Sheathing" fullWidth />

            {/* Sheathing Installation */}
            <TextField label="Sheathing Installation" fullWidth />
          </div>
        ))}

        {/* Add Level Button */}
        <div className="mt-4">
          <IconButton color="primary" onClick={addWallLevel}>
            <AddIcon /> Add Level
          </IconButton>
        </div>
      </div>

      {/* Additional Wall Dimensions */}
      <div className="grid grid-cols-3 gap-4 mb-8">
        <TextField label="Interior Wall Width" fullWidth />
        <TextField label="Exterior Wall Width" fullWidth />
        <TextField label="Garage Wall Width" fullWidth />
      </div>

      {/* Google Maps Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="modal-title">Location: {address}</h2>
          <iframe
            title="Google Maps"
            width="100%"
            height="100%"
            src={googleMapsUrl}
            allowFullScreen
            loading="lazy"
          />
        </Box>
      </Modal>
    </div>
  );
};

export default BlueprintSpecSheet;
