import React from "react";
import { useTable } from "react-table";

const StockedItems = ({ woStatus }) => {
  // Filter the data to include only items where Special is false
  const filteredData = woStatus.filter((item) => item.Special === false);

  // Define the table columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Product Code",
        accessor: "ProductCode",
      },
      {
        Header: "Description",
        accessor: "Description",
        Cell: ({ cell: { value } }) => value[1], // Join array values into a string
      },
      {
        Header: "Quantity",
        accessor: "Quantity",
      },
    ],
    []
  );

  // Table rendering logic
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: filteredData,
    });

  return (
    <div className="flex flex-col w-full">
      <h2 className="underline font-black">Stocked Items</h2>

      <table
        {...getTableProps()}
        style={{ border: "1px solid black", marginBottom: "20px" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    borderBottom: "solid 3px red",
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{ border: "1px solid black", padding: "4px" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StockedItems;
