import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CheckWebAdmin from "../../components/User/CheckWebAdmin";

const ThumbnailRow = ({ items, onEdit, onDelete }) => {
  return (
    <div className="overflow-x-auto flex">
      <div className="flex space-x-4 p-2">
        {items.map((item) => (
          <div
            key={item._id}
            className="flex flex-col items-center justify-between w-40 p-4 bg-white rounded-lg shadow-md"
          >
            <img
              src={`https://node.bigclumber.com/api/cloud/website/blog/displayThumbnail${item.thumbnailUrl}`}
              //src={`https://placehold.co/400`}
              alt={item.title}
              className="w-32 h-32 rounded-lg object-cover"
            />
            <h3 className="mt-2 text-sm font-medium text-gray-700">
              {item.title}
            </h3>
            <div className="flex space-x-2 mt-2">
              <button
                onClick={() => onEdit(item)}
                className="px-3 py-1 text-sm text-white bg-blue-500 rounded hover:bg-blue-600"
              >
                Edit
              </button>
              <button
                onClick={() => onDelete(item._id)}
                className="px-3 py-1 text-sm text-white bg-red-500 rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const WebsiteEditor = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  const [id, setID] = useState("");
  const [editing, setEditing] = useState(false);

  const [allBlogs, setAllBlogs] = useState([]);

  const handleEdit = (blog) => {
    setEditing(true);
    setTitle(blog.title);
    setSummary(blog.summary);
    setContent(blog.content);
    setCategory(blog.category);
    setID(blog._id);
  };

  const discardEdit = () => {
    setEditing(false);
    setTitle("");
    setSummary("");
    setContent("");
    setCategory("");
  };

  const handleDelete = async (id) => {
    const formData = new FormData();
    formData.append("id", id);

    console.log(formData);

    try {
      const response = await fetch("/api/cloud/website/deleteBlog", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Blog Deleted successfully!");
        getAllBlogs();
      } else {
        alert("Failed to delete blog");
      }
    } catch (error) {
      console.error("Error deleting blog:", error);
      alert("An error occurred while deleting the blog");
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadSuccess(false);
    setUploadError(false);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("materialTrendsPhoto", file);

    try {
      const response = await fetch("/api/cloud/website/insertMaterialTrends", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log(response);
        setUploadSuccess(true);
        setFile(null); // Clear the file input after a successful upload
      } else {
        setUploadError(true);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError(true);
    }
  };

  const getAllBlogs = async () => {
    return fetch(`/api/cloud/website/getAllBlogs`)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        setAllBlogs(responseJson);
      })
      .catch((error) => {
        //console.error(error);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  const handleBlogUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("summary", summary);
    formData.append("category", category);
    formData.append("id", id);

    try {
      const response = await fetch("/api/cloud/website/updateBlog", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Blog Updated successfully!");
        getAllBlogs();
        setTitle("");
        setContent("");
        setSummary("");
        setImage(null);
        setCategory("");
      } else {
        alert("Failed to update blog");
      }
    } catch (error) {
      console.error("Error updating blog:", error);
      alert("An error occurred while updating the blog");
    }
  };

  const handleBlogSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("thumbnail", image);
    formData.append("summary", summary);
    formData.append("category", category);

    try {
      const response = await fetch("/api/cloud/website/createBlog", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Blog created successfully!");
        getAllBlogs();
        setTitle("");
        setContent("");
        setSummary("");
        setImage(null);
        setCategory("");
      } else {
        alert("Failed to create blog");
      }
    } catch (error) {
      console.error("Error creating blog:", error);
      alert("An error occurred while creating the blog");
    }
  };

  //const toolbarOptions -> ['First','Second','Third','...nth']
  //{{header: [1,2,3,4,5]}}

  const toolbarOptions = [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["blockquote", "code-block"],
    ["clean"],
  ];

  return (
    <div className="p-2">
      <CheckWebAdmin />
      {allBlogs.length > 0 ? (
        <>
          <div className="p-4 bg-slate-300 m-2 rounded-sm border border-black">
            <h2 className="text-xl font-bold mb-4">
              Update Material Trends Image
            </h2>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="bg-white"
            />
            <button
              onClick={handleFileUpload}
              className="m-2 p-2 bg-blue-400 text-white rounded-sm"
              disabled={!file} // Disable the button if no file is selected
            >
              Upload Image
            </button>
            {uploadSuccess && (
              <p className="text-green-500">Image uploaded successfully!</p>
            )}
            {uploadError && (
              <p className="text-red-500">
                Error uploading image. Please try again.
              </p>
            )}
          </div>

          {/* Create a Blog Post Section */}
          <div className="p-4 bg-slate-300 m-2 rounded-sm border border-black">
            <h2 className="text-xl font-bold mb-4">Create a Blog Post</h2>
            <form onSubmit={handleBlogSubmit}>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Blog Title"
                required
                className="p-2 border mb-2 w-full"
              />
              <input
                type="text"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                placeholder="Enter a Short Summary of Blog"
                required
                className="p-2 border mb-2 w-full"
              />

              <label htmlFor="category">Category</label>
              <select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
                className="p-2 border mb-2 w-full"
              >
                <option value="" disabled>
                  Select a category
                </option>
                <option value="KitchensBathrooms">Kitchens & Bathrooms</option>
                <option value="Cabinets">Cabinets</option>
                <option value="Roofing">Roofing</option>
                <option value="HeatingCooling">Heating & Cooling</option>
                <option value="WindowsDoors">Windows & Doors</option>
                <option value="Insulation">Insulation</option>
                <option value="Decking">Decking</option>
                <option value="Siding">Siding</option>
              </select>

              <p>Blog Content</p>
              <ReactQuill
                value={content}
                onChange={setContent}
                placeholder="Write your blog content here..."
                className="mb-2 bg-white"
                modules={{ toolbar: toolbarOptions }}
              />

              {!editing ? (
                <>
                  <p>Blog Thumbnail</p>
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    accept="image/*"
                    required
                    className="m-2 bg-white"
                  />

                  <button
                    className="m-2 p-2 bg-blue-400 text-white rounded-sm"
                    type="submit"
                  >
                    Create Blog
                  </button>
                </>
              ) : (
                <div>
                  <button
                    className="m-2 p-2 bg-green-400 text-white rounded-sm"
                    onClick={(e) => {
                      handleBlogUpdate(e);
                    }}
                  >
                    Update Blog
                  </button>
                  <button
                    className="m-2 p-2 bg-red-400 text-white rounded-sm"
                    onClick={(e) => {
                      discardEdit();
                    }}
                  >
                    Cancel Edit
                  </button>
                </div>
              )}
            </form>
          </div>

          <div className="flex flex-col p-4 bg-slate-300 m-2 rounded-sm border border-black">
            <h1 className="text-2xl font-bold">All Blogs</h1>
            <ThumbnailRow
              items={allBlogs}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </div>
        </>
      ) : (
        <CheckWebAdmin />
      )}
    </div>
  );
};

export default WebsiteEditor;
