import AddressValid from "../../views/Admin/AddressValid";
import ChangeLog from "../../views/Admin/ChangeLog";
import Changes from "../../views/Admin/Changes";
import CreateNotification from "../../views/Admin/CreateNotification";
import DisplayFeedback from "../../views/Admin/DisplayFeedback";
import UserManagement from "../../views/Admin/UserManagement";
import GrangerYardMap from "../../views/Admin/GrangerYardMap";
import InsertKit from "../../views/Dashboard/DoorBuilder/InsertKit";
import Messages from "../../views/Dashboard/Messages/Messages";
import ScheduleOrders from "../../views/Dashboard/Orders/ScheduleOrders";
import ExteriorOrders from "../../views/Dashboard/WindowDoor/ExteriorOrders";
import DashboardHome from "../../views/Dashboard/WindowDoor/RequestList";
import RequestSummary from "../../views/Dashboard/WindowDoor/RequestSummary";
import Requests from "../../views/Dashboard/WindowDoor/Requests";
import AcceptRequest from "../../views/Dashboard/WindowDoor/Requests/accept";
import AcceptAlt from "../../views/Dashboard/WindowDoor/Requests/acceptAlt";
import RejectRequest from "../../views/Dashboard/WindowDoor/Requests/reject";
import ScheduleEarliest from "../../views/Dashboard/WindowDoor/Requests/schEarliest";
import NotFound from "../../views/Error/NotFound";
import ForkManager from "../../views/Fleet/Forklifts/ForkManager";
import LocationManager from "../../views/Fleet/Forklifts/LocationManager";
import ViewEntries from "../../views/Forms/Dashboard/ViewEntries";
import InteriorDoor from "../../views/Forms/Door/InteriorDoor";
import Drafts from "../../views/Forms/Misc/Drafts";
import QuoteOrderForm from "../../views/Forms/QuoteOrderForm";
import Help from "../../views/Help/Help";
import Home from "../../views/Home/Home";
import Feedback from "../../views/Misc/Feedback";
import Profile from "../../views/Profile/profile";
import ManagerLeads from "../../views/Webleads/ManagerLeads";
import SalesLeads from "../../views/Webleads/SalesLeads";
import Summary from "../../views/Webleads/Summary";
import SummaryBranchSpecific from "../../views/Webleads/SummaryBranchSpecific";
import WebLead from "../../views/Webleads/WebLead";
import DisplayForklifts from "../Fleet/Forklifts/DisplayForklifts";
import InitUser from "../InitUser/InitUser";
import DoorBuilder from "../WindowDoor/DoorBuilder";
import Weather from "../../views/Dashboard/Weather";
import ForkliftForm from "../../views/Fleet/Forklifts/ForkliftForm";
import ForkDisplay from "../../views/Fleet/Forklifts/ForkDisplay";
import DisplayEntries from "../../views/Fleet/Forklifts/DisplayEntries";
import Logout from "../../auth/Logout";
import WebLeadMap from "../../views/Webleads/WebLeadMap";
import WindowServiceRequestForm from "../WindowDoor/WindowServiceRequestForm";
import TaxCert from "../../views/Misc/TaxCert";
import ManagePhone from "../../views/Admin/ManagePhone";
import VehicleManager from "../../views/Fleet/VehicleManager";
import DisplayVehicles from "../../components/Fleet/DisplayVehicles";
import VehicleForm from "../../views/Fleet/VehicleForm";
import SharePoint from "../../views/Dashboard/SharePoint";
import WithoutContact from "../../views/Webleads/WithoutContact";
import ConvertedToSale from "../../views/Webleads/ConvertedToSale";
import CustomerKPIs from "../../views/Dashboard/CustomerKPIs";
import RequestAccess from "../../views/Misc/RequestAccess";
import ExternalAuth from "../Navigation/ExternalAuth";
import CommonStock from "../../views/SBCF/CommonStock";
import UserPortal from "../../views/Admin/UserPortal";
import SlabOptions from "../../views/Forms/Misc/SlabOptions";
import UserChecklist from "../../views/Admin/UserChecklist";
import InteriorDoorFormSummary from "../../views/Forms/Door/InteriorDoorFormSummary";
import SummaryNew from "../../views/Webleads/SummaryNew";
import SummaryBranchSpecificNew from "../../views/Webleads/SummaryBranchSpecificNew";
import WebleadManager from "../../views/WebleadsV2/WebleadManager";
import ScreenShare from "../../views/Admin/ScreenShare";
import UserScreenShareList from "../../views/Admin/UserScreenShareList";
import { ContextProvider } from "../../views/Admin/Utilities/Context";
import ScreenShareNavBar from "../../views/Admin/ScreenShareNavBar";
import RecognitionForm from "../../views/Forms/Misc/RecognitionForm";
import DeliveryIssueForm from "../../views/Forms/Misc/DeliveryIssueForm";
import RecognitionEntries from "../../views/Forms/Misc/RecognitionEntries";
import TrendEditor from "../../views/Admin/WebsiteEditor";
import WebsiteEditor from "../../views/Admin/WebsiteEditor";
import MillScanner from "../../views/Misc/MillScanner";
import BlueprintSpecSheet from "../../views/SBCF/BlueprintSpecSheet";

export const routes = [
  {
    name: "Big C Node - Home",
    description: "Big C Node - Home",
    path: "/",
    roles: ["*"],
    component: <Home />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Big C Node - Initialize User",
    description: "Big C Node - Initialize User",
    path: "/inituser",
    roles: ["*"],
    component: <InitUser />,
    authHeader: false,
    footer: false,
  },

  {
    name: "Big C Node - Profile",
    description: "Big C Node - Profile",
    path: "/profile",
    roles: ["*"],
    component: <Profile />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Big C Node - Exterior Orders",
    description: "Big C Node - Exterior Orders",
    path: "/windowdoor/exteriororders",
    roles: ["*"],
    component: <ExteriorOrders />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Big C Node - Exterior Orders Summary",
    description: "Big C Node - Exterior Orders Summary",
    path: "/windowdoor/summary",
    roles: ["*"],
    component: <RequestSummary />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Big C Node - Requests",
    description: "Big C Node - Requests",
    path: "/windowdoor/requests",
    roles: ["*"],
    component: <Requests />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Accept Request - Exterior Orders",
    description: "Accept a request for exterior products.",
    path: "/acceptRequest/:id",
    roles: ["*"],
    component: <AcceptRequest />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Reject Request - Exterior Orders",
    description: "Reject a request for exterior products.",
    path: "/rejectRequest/:id",
    roles: ["*"],
    component: <RejectRequest />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Accept Alt Request - Exterior Orders",
    description: "Accept a request for exterior products.",
    path: "/acceptAlt/:ref",
    roles: ["*"],
    component: <AcceptAlt />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Schedule Earliest Request - Exterior Orders",
    description: "Accept a request for exterior products.",
    path: "/schEarliest/:ref",
    roles: ["*"],
    component: <ScheduleEarliest />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Dashboard Home - Admin",
    description: "View and manage all delivery requests for exterior products.",
    path: "/windowdoor/admin/requestlist",
    roles: ["*"],
    component: <DashboardHome />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Fleet Location Manager",
    description: "Fleet Location Manager",
    path: "/fleet/forks/locationmanager",
    roles: ["*"],
    component: <LocationManager />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Fleet Forklift Manager",
    description: "Manage forklifts and their locations.",
    path: "/fleet/forks/forkmanager",
    roles: ["*"],
    component: <ForkManager />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Forklift Check-In Dashboard",
    description: "Fork Check-In",
    path: "/fleet/forks/dashboard/:branch",
    roles: ["*"],
    component: <ForkDisplay />,
    authHeader: false,
    footer: false,
  },
  {
    name: "Forklift Display",
    description: "Display forklifts and their locations.",
    path: "/fleet/forks/forkmanager/fleet/forks/view/:location",
    roles: ["*"],
    component: <DisplayForklifts />,
    authHeader: true,
    footer: true,
  },

  {
    name: "My Forms",
    description: "View all forms submitted by the user.",
    path: "/forms/viewall",
    roles: ["*"],
    component: <ViewEntries />,
    authHeader: true,
    footer: true,
  },

  {
    name: "New RFQ Form",
    description: "Create a new RFQ form.",
    path: "/forms/new/rfq",
    roles: ["*"],
    component: <QuoteOrderForm />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Interior Door Form",
    description: "Interior Door Form",
    path: "/forms/doors/interior",
    roles: ["*"],
    component: <InteriorDoor />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Schedule Orders",
    description: "Schedule Orders",
    path: "/dashboard/orders/schedule",
    roles: ["*"],
    component: <ScheduleOrders />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Address Validation",
    description: "Validate an address.",
    path: "/admin/cloud/addressvalidation",
    roles: ["*"],
    component: <AddressValid />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Manager Users",
    description: "Manage users.",
    path: "/admin/user/userManager",
    roles: ["*"],
    component: <UserManagement />,
    authHeader: true,
    footer: true,
    footer: true,
    requiresMFA: true,
  },
  //Webleads V2 Routes
  {
    name: "Web Leads - Home",
    description: "Web Leads - Home",
    path: "webleads/v2/manager",
    roles: ["Weblead Manager", "Admin"],
    component: <WebleadManager />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Web Leads - Summary V2",
    description: "",
    path: "webleads/summary/v2",
    roles: ["*"],
    component: <SummaryNew />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Help - Big C Node",
    description: "Get help with the website.",
    path: "help",
    roles: ["*"],
    component: <Help />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Interior Door Slab Options",
    description: "Interior Door Slab Options",
    path: "admin/doors/slaboptions",
    roles: ["Admin"],
    component: <SlabOptions />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Messages",
    description: "Send and receive messages.",
    path: "/messages",
    roles: ["*"],
    component: <Messages />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Page Not Found",
    description: "Page Not Found",
    path: "*",
    roles: ["*"],
    component: <NotFound />,
    authHeader: false,
  },
  {
    name: "Create Notification",
    description: "Create Notification",
    path: "/admin/createnotification",
    roles: ["Admin"],
    component: <CreateNotification />,
    authHeader: true,
    footer: true,
  },

  {
    name: "My Web Leads",
    description: "My Web Leads",
    path: "/webleads",
    roles: ["*"],
    component: <SummaryBranchSpecificNew />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Feedback",
    description: "feedback",
    path: "/feedback",
    roles: ["*"],
    component: <Feedback />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Display Feedback",
    description: "display feedback",
    path: "/admin/displayfeedback",
    roles: ["Admin"],
    component: <DisplayFeedback />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Node Changes",
    description: "updates",
    path: "/admin/changes",
    roles: ["Admin"],
    component: <Changes />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Node Changelog",
    description: "update log",
    path: "/admin/changelog",
    roles: ["Admin"],
    component: <ChangeLog />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Door Builder",
    description: "Door Builder",
    path: "/windowdoor/doorbuilder",
    roles: ["Admin"],
    component: <DoorBuilder />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Granger Yard Map",
    description: "Map of the granger lumber yard.",
    path: "/admin/grangeryardmap",
    roles: ["Admin"],
    component: <GrangerYardMap />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Weather",
    description: "local weather for each big c branch",
    path: "/dashboard/weather",
    roles: ["*"],
    component: <Weather />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Weather For Node",
    description: "local weather for each big c branch",
    path: "/dashboard/weather/display/:branchlocation",
    roles: ["*"],
    component: <Weather />,
    authHeader: false,
    footer: false,
  },
  {
    name: "Forklift Checklist",
    description: "Check forklift form",
    path: "/fleet/forks/forkliftform/:branch/:unit",
    roles: ["*"],
    component: <ForkliftForm />,
    authHeader: false,
    footer: false,
  },
  {
    name: "Forklift Entries",
    description: "Check forklift form",
    path: "/fleet/forks/entries/:unit",
    roles: ["*"],
    component: <DisplayEntries />,
    authHeader: false,
    footer: false,
  },
  {
    name: "Logout",
    description: "Logout",
    path: "/logout",
    roles: ["*"],
    component: <Logout />,
    authHeader: false,
    footer: false,
  },
  {
    name: "Big C Web Leads Map",
    description: "Web Lead Pin Map",
    path: "/external/webleads/:key",
    roles: ["*"],
    component: (
      <>
        <ExternalAuth externalKey="8wQPtXg9nT7bFMqWVdgne5" />
        <WebLeadMap />
      </>
    ),
    authHeader: false,
    footer: false,
  },

  {
    name: "Web Lead Pin Map",
    description: "Web Lead Pin Map",
    path: "/webleads/webleadmap",
    roles: ["*"],
    component: <WebLeadMap />,
    authHeader: true,
    footer: true,
  },

  {
    name: "Customer Certificate Data",
    description: "Customer Certificate Data",
    path: "/tax/certificates",
    roles: ["*"],
    component: <TaxCert />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Window Service Request Form",
    description: "Window Service Request Form",
    path: "/windowdoor/windowservicerequestform",
    roles: ["Admin"],
    component: <WindowServiceRequestForm />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Phone Management Tool",
    description: "Phone Management Tool",
    path: "/admin/managephone",
    roles: ["Admin"],
    component: <ManagePhone />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Vehicle Manager",
    description: "Vehicle Manager",
    path: "/fleet/vehiclemanager",
    roles: ["Admin"],
    component: <VehicleManager />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Vehicle Display",
    description: "Display vehicles and their locations.",
    path: "/fleet/vehiclemanager/view/:location",
    roles: ["*"],
    component: <DisplayVehicles />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Vehicle Checklist",
    description: "Complete Vehicle Checklist",
    path: "/fleet/vehiclemanager/form/:unit",
    roles: ["*"],
    component: <VehicleForm />,
    authHeader: false,
    footer: false,
  },
  {
    name: "BigC SharePoint",
    description: "Big C sharepoint file system",
    path: "/dashboard/sharepoint",
    roles: ["*"],
    component: <SharePoint />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Lead Without Contact",
    description: "Webleads without contact",
    path: "/webleads/nocontact",
    roles: ["*"],
    component: <WithoutContact />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Converted To Sale",
    description: "Leads converted to sale",
    path: "/webleads/convertedtosale",
    roles: ["*"],
    component: <ConvertedToSale />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Customer KPI's",
    description: "Customer KPI data",
    path: "/dashboard/CustomerKPIs",
    roles: ["*"],
    component: <CustomerKPIs />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Request Webtrack Access",
    description: "Request Webtrack Access",
    path: "/misc/webtrackaccess",
    roles: ["*"],
    component: <RequestAccess />,
    authHeader: false,
    footer: false,
  },
  {
    name: "Truss and Panel Documents",
    description: "Truss and Panel Documents",
    path: "/sbcf/documents",
    roles: ["*"],
    component: <CommonStock />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Big C User Portal",
    description: "Big C User Portal",
    path: "/admin/user/portal",
    roles: ["Admin"],
    component: <UserPortal />,
    authHeader: true,
    footer: true,
    requiresMFA: true,
  },
  {
    name: "Big C User Portal Checklist",
    description: "Big C User Portal Checklist",
    path: "/admin/user/portal/checklist",
    roles: ["Admin"],
    component: <UserChecklist />,
    authHeader: true,
    footer: true,
    requiresMFA: true,
  },
  {
    name: "Big C User ScreenShare",
    description: "Big C User ScreenShare",
    path: "/admin/screenshare",
    roles: ["Admin"],
    component: (
      <ContextProvider>
        <ScreenShare />
      </ContextProvider>
    ),
    authHeader: true,
    footer: true,
    requiresMFA: false,
  },
  {
    name: "Big C User ScreenShare",
    description: "Big C User ScreenShare",
    path: "/admin/screenshare/nav",
    roles: ["*"],
    component: <ScreenShareNavBar />,
    authHeader: false,
    footer: false,
    requiresMFA: false,
  },
  {
    name: "Big C User ScreenShare",
    description: "Big C User ScreenShare",
    path: "/admin/screensharelist",
    roles: ["Admin"],
    component: <UserScreenShareList />,
    authHeader: true,
    footer: true,
    requiresMFA: true,
  },
  {
    name: "Interior Door Form Summary",
    description: "Interior Door Form Summary",
    path: "/forms/IntDoorFormSummary",
    roles: ["Admin", "Executive Team"],
    component: <InteriorDoorFormSummary />,
    authHeader: true,
    footer: true,
    requiresMFA: false,
  },
  {
    name: "Caught in the Act Form",
    description: "Caught in the Act Form",
    path: "/forms/recognition",
    roles: ["*"],
    component: <RecognitionForm />,
    authHeader: true,
    footer: true,
    requiresMFA: false,
  },
  {
    name: "Caught in the Act Form Mobile",
    description: "Caught in the Act Form",
    path: "/mobile/forms/recognition/:name/:mobile",
    roles: ["*"],
    component: <RecognitionForm />,
    authHeader: false,
    footer: false,
    requiresMFA: false,
  },
  {
    name: "Caught in the Act Entries",
    description: "Caught in the Act Entries",
    path: "/forms/recognition/entries",
    roles: ["Manager"],
    component: <RecognitionEntries />,
    authHeader: true,
    footer: true,
    requiresMFA: false,
  },
  {
    name: "Delivery Issue Form",
    description: "Delivery Issue Form",
    path: "/forms/issue/delivery",
    roles: ["*"],
    component: <DeliveryIssueForm />,
    authHeader: true,
    footer: true,
    requiresMFA: false,
  },
  {
    name: "Blueprint Spec Sheet",
    description: "Blueprint Spec Sheet",
    path: "/sbcf/specsheet",
    roles: ["Admin"],
    component: <BlueprintSpecSheet />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Big C Website Editor",
    description: "Big C Website Editor",
    path: "/admin/webeditor",
    roles: ["Web Admin"],
    component: <WebsiteEditor />,
    authHeader: true,
    footer: true,
  },
  {
    name: "Mill Document Scanner",
    description: "Mill Document Scanner",
    path: "/mill/scanner/:key",
    roles: ["*"],
    component: (
      <>
        <ExternalAuth externalKey="8wQPtXg12ij3o1zkxWVdgne5" />
        <MillScanner />
      </>
    ),
    authHeader: false,
    footer: true,
  },
];
