export const sideNavWindowDoor = [
  {
    name: "My Exterior Orders",
    path: "/windowdoor/exteriororders",
    roles: ["*"],
    pin: false,
  },
  {
    name: "My Requests",
    path: "/windowdoor/requests",
    roles: ["*"],
    pin: false,
  },
  {
    name: "Request Manager",
    path: "/windowdoor/admin/requestlist",
    roles: ["Window Door Manager", "Admin"],
    pin: false,
  },
  {
    name: "Request Summary",
    path: "/windowdoor/summary",
    roles: ["Admin"],
    pin: false,
  },
  {
    name: "Window Service Request Form",
    path: "/windowdoor/windowservicerequestform",
    roles: ["Admin"],
    pin: false,
  },
];

export const sideNavSBCF = [
  {
    name: "Truss and Panel Documents",
    path: "/sbcf/documents",
    roles: ["*"],
    pin: false,
  },
];
export const sideNavRFQForms = [
  {
    name: "New RFQ",
    path: "/forms/new/rfq",
    roles: ["*"],
    pin: false,
  },
  {
    name: "Interior Door Form Summary",
    path: "/forms/IntDoorFormSummary",
    roles: ["Admin", "RFQ Tester"],
    pin: false,
  },
  {
    name: "My Forms",
    path: "/forms/viewall",
    roles: ["*"],
    pin: false,
  },
];
export const sideNavAdminLinks = [
  {
    name: "User Manager",
    path: "/admin/user/userManager",
    roles: ["Admin"],
    pin: false,
  },
  {
    name: "User Portal",
    path: "/admin/user/portal",
    roles: ["Admin"],
    pin: false,
  },
  {
    name: "Create Notification",
    path: "/admin/createnotification",
    roles: ["Admin"],
    pin: false,
  },
  {
    name: "Feedback",
    path: "/admin/displayfeedback",
    roles: ["Admin"],
    pin: false,
  },
  {
    name: "Add Change",
    path: "/admin/changes",
    roles: ["Admin"],
    pin: false,
  },
  {
    name: "Changelog",
    path: "/admin/changelog",
    roles: ["Admin"],
    pin: false,
  },
  {
    name: "Big C Web Editor",
    path: "/admin/webeditor",
    roles: ["Admin"],
    pin: false,
  },
];
export const sideNavMyDashboard = [
  {
    name: "Weather",
    path: "/dashboard/weather",
    roles: ["*"],
    pin: false,
  },
  { name: "Feedback", path: "/feedback", roles: ["*"], pin: false },
  {
    name: "Share Point",
    path: "/dashboard/sharepoint",
    roles: ["*"],
    pin: false,
  },
];
export const sideNavFleetForklifts = [
  {
    name: "Location Manager",
    path: "/fleet/forks/locationmanager",
    roles: ["Admin", "Fleet Manager"],
    pin: false,
  },
  {
    name: "Forklift Manager",
    path: "/fleet/forks/forkmanager",
    roles: ["Admin", "Fleet Manager"],
    pin: false,
  },
  {
    name: "Vehicle Manager",
    path: "/fleet/vehiclemanager",
    roles: ["Admin", "Fleet Manager"],
    pin: false,
  },
];
export const sideNavWebLeads = [
  {
    name: "Web Lead Manager",
    path: "/webleads",
    roles: ["*"],
    pin: false,
  },
  {
    name: "Web Lead Pin Map",
    path: "/webleads/webleadmap",
    roles: ["Web Lead Manager", "Admin"],
    pin: false,
  },
];
