import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectRoles } from "../../features/roleSlice";

const CheckWebAdmin = () => {
  const navigate = useNavigate();

  const role = useSelector(selectRoles);

  useEffect(() => {
    if (
      !role?.roles?.includes("Admin") &&
      !role?.roles?.includes("Web Admin")
    ) {
      navigate("/");
    }
  }, [role]);

  return <></>;
};

export default CheckWebAdmin;
